import { setLastPageVisited } from '@helpers/history';
import { isServer } from '@utils/isServer';
import { Layout } from 'antd';
import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import NextHead from 'next/head';
import Router from 'next/router';
import React, { useEffect } from 'react';

declare const window: any;

const { Content } = Layout;
const isProdution = process.env.NODE_ENV === 'production';

const NavBar = dynamic(() => import('@/components2/NavBar'), {
  ssr: true,
});
const AntFooter = dynamic(() => import('@/components2/Footer'), {
  ssr: true,
});

function FacebookPixel() {
  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        window.ReactPixel = ReactPixel;
        ReactPixel.init('522092471979398');
        ReactPixel.pageView();
        Router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
          // if (url.indexOf('Confirmed') > 0) {
          //   const split = url.split('/');
          //   ReactPixel.track('Purchase', { code: split[split.length - 1] });
          // }
        });
      });
  });
  return null;
}

export function reportWebVitals(metric: { label: string }) {
  if (metric.label === 'custom') {
    console.log(metric);
  }
}

const DefaultLayout2 = ({ children, airports }) => {
  useEffect(() => {
    setLastPageVisited();
  });

  return (
    <Layout>
      <NextHead>
        <meta name="msvalidate.01" content="70D794CFD1BC46E43E6BC2236189BAD2" />
        <meta
          property="og:url"
          content={!isServer ? window.location.toString() : undefined}
          key="url"
        />
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:title" content="On Air Parking" key="og:title" />
        <meta
          property="og:description"
          content="On Air Parking"
          key="og:description"
        />
        <meta
          property="og:image"
          content="https://www.onairparking.com/assets/public/image/logo_app.png"
          key="og:image"
        />
      </NextHead>
      {isProdution && <FacebookPixel />}
      <NavBar airports={airports} isHomepage={true} />
      <Content className="site-layout">{children}</Content>
      <AntFooter />
    </Layout>
  );
};

export default observer(DefaultLayout2);
