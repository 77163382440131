import { IFacility } from '@/types';

export function formatMonetary(val: number): string {
  return '$' + Math.round(val * 100.0) / 100.0;
}

export function cround(val: number, frac: number): number {
  const cfrag = Math.pow(10, frac);
  return Math.round(val * cfrag) / cfrag;
}

export function localReplaceAll(originalString, find, replace) {
  return originalString.replace(new RegExp(find, 'g'), replace);
}
export function stripPhoneNumber(phone) {
  if (phone) {
    const res = phone.replace(/\D/g, '');
    if (res.length > 10) {
      return res.substr(0, 10);
    }
  }
  return phone;
}

export function mapRateSearchName(item: IFacility): string {
  if (item.facility_use_ratetable && item.early_bird) {
    return 'Early Bird Rate';
  }
  if (item.facility_use_ratetable) {
    return 'Hourly Rate';
  }
  if (item.facility_hourly_selling_price) {
    return 'Hourly Rate';
  }
  if (item.facilityParkingType == 3) {
    return 'Sleep and Park';
  }
  if (item.facilityParkingType == 4) {
    return 'Monthly Park';
  }
  if (item.facility_24h_extension == 5) {
    return '12 hours max rate';
  }
  return 'Daily Rate';
}

export function mapCouponError(error: string): string {
  switch (error) {
    case 'not_logged':
      return 'Please log in to redeem';
    case 'not_found':
      return 'Invalid coupon code';
    case 'member_not_found':
      return 'Invalid member name'; //
    case 'used_public':
      return 'Coupon already redeemed'; //
    case 'already_used':
      return 'This coupon has already been used';
  }
  return error;
}
export function mapReviewStars(review_avg) {
  if (!review_avg) return 4.5;
  return Math.round(review_avg * 2) / 2.0;
}

export const mapFormEncoded = (dataRequest: {}) => {
  //application/x-www-form-urlencoded;charset=UTF-8
  const formBody = [];
  for (var property in dataRequest) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(dataRequest[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  return formBody.join('&');
};

const str = () =>
  (
    '00000000000000000' + (Math.random() * 0xffffffffffffffff).toString(16)
  ).slice(-16);

export const uuidv4 = () => {
  const a = str();
  const b = str();
  return (
    a.slice(0, 8) +
    '-' +
    a.slice(8, 12) +
    '-4' +
    a.slice(13) +
    '-a' +
    b.slice(1, 4) +
    '-' +
    b.slice(4)
  );
};

export const findCheapestFacility = (facilities) => {
  const validFacilities = facilities.filter(
    (facility) => facility.date_sold_out !== 1
  );

  if (validFacilities.length > 0) {
    const sortedFacilities = validFacilities.sort(
      (a, b) => a.facility_selling_price - b.facility_selling_price
    );

    return sortedFacilities[0];
  } else {
    return null;
  }
};
