import { loadData } from '@/services/myReservationService';
import { IFacility, IPriceDescription, IValidateWarning } from '@/types';
import { Dayjs } from 'dayjs';
import { makeAutoObservable } from 'mobx';
import reservationService from '../services/reservationService';
import { isServer } from '../utils/isServer';

class ReservationStore {
  reviewsTotal = 1;
  reviewsByStarTotal = { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1 };
  reviewsByStarData = { 1: [], 2: [], 3: [], 4: [], 5: [] };
  reviewsByStarList = { 1: [], 2: [], 3: [], 4: [], 5: [] };
  reviewsData = [];
  reviewsList = [];
  groupedDates = [];
  initReviewsLoading = true;
  reviewsLoading = false;
  bookingPriceDetail: IPriceDescription = {} as IPriceDescription;
  loadingPrice = false;
  count = 5;
  coupon: any = {};
  error_coupon: string = '';
  coupon_applied: string = '';
  showCoupon = false;
  currentData = [];
  pastData = [];
  paymentLoading = false;
  facilityStatus: string;
  isModalConfirmDateTimeVisible = false;

  constructor() {
    makeAutoObservable(this);
  }

  setPaymentLoading(value: boolean) {
    this.paymentLoading = value;
  }

  setModalConfirmDateTimeVisible(value) {
    this.isModalConfirmDateTimeVisible = value;
  }

  async loadTabData(today: Dayjs) {
    try {
      const data = await loadData();
      this.currentData = data?.result
        ?.filter((item) => {
          const checking = item.dayjs_reservation_dt_checkin.add(1, 'day');
          return today.isSame(checking) || today.isBefore(checking);
        })
        .sort((a, b) =>
          a.dayjs_reservation_dt_checkin.isBefore(
            b.dayjs_reservation_dt_checkin
          )
            ? 1
            : 0
        );
      this.pastData = data?.result
        ?.filter((item) => {
          const checking = item.dayjs_reservation_dt_checkin.add(1, 'day');
          return today.isAfter(checking);
        })
        .sort((a, b) =>
          a.dayjs_reservation_dt_checkin.isBefore(
            b.dayjs_reservation_dt_checkin
          )
            ? 1
            : 0
        );
    } catch (error) {
      console.log(error);
    }
  }

  setInitReviewsLoading(value) {
    this.initReviewsLoading = value;
  }

  setShowCoupon(value) {
    this.showCoupon = value;
  }

  setReviewsLoading(value) {
    this.reviewsLoading = value;
  }

  setReviewsTotal(value) {
    this.reviewsTotal = value;
  }

  setReviewsData(reviews) {
    this.reviewsData = reviews;
  }

  setReviewsList(reviews) {
    this.reviewsList = reviews;
  }

  async loadReviewsBySortStar(facility: IFacility) {
    const count = 0;
    try {
      if (facility.facility_url_code.startsWith('arv_')) {
        const reviews = await reservationService.GetReviewsThirdParty(
          facility.facility_url_code
        );
        this.setInitReviewsLoading(false);
        this.setReviewsLoading(false);
        this.setReviewsData(reviews);
        this.setReviewsTotal(reviews.length ? reviews.length : 1);
        this.setReviewsList(reviews);
        return;
      }
      this.setReviewsLoading(true);
      const reviews = await reservationService.GetReviewsBySortStar(
        facility.facility_id,
        count,
        1
      );

      this.setInitReviewsLoading(false);
      this.setReviewsLoading(false);
      this.setReviewsData(reviews.data);
      this.setReviewsTotal(reviews.recordsTotal ? reviews.recordsTotal : 1);
      this.setReviewsList(reviews.data);
    } catch (error) {
      console.log(error);
    }
  }

  async onLoadMoreReviewsSortStar(facility_id) {
    try {
      this.setReviewsLoading(true);
      const reviews = await reservationService.GetReviewsBySortStar(
        facility_id,
        this.count,
        1
      );
      this.count = this.count + reservationService.totalLoaded;
      this.setReviewsLoading(false);
      const data = this.reviewsData.concat(reviews.data);
      this.setReviewsList(data);
      this.setReviewsData(data);
      !isServer && window.dispatchEvent(new Event('resize'));
    } catch (error) {
      console.log(error);
    }
  }

  async onLoadMoreReviewsByStar(facility_id, star) {
    try {
      this.setReviewsLoading(true);
      const reviews = await reservationService.GetReviewsByStar(
        facility_id,
        this.count,
        1,
        star
      );
      this.setReviewsLoading(false);
      const data = this.reviewsByStarData[star].concat(reviews.data);
      const newData = { ...this.reviewsByStarList };
      newData[star] = data;
      this.reviewsByStarList = newData;
      this.reviewsByStarData = newData;
      !isServer && window.dispatchEvent(new Event('resize'));
    } catch (error) {
      console.log(error);
    }
  }
  async onLoadMoreReviews(facility_id) {
    try {
      this.setReviewsLoading(true);
      const reviews = await reservationService.GetReviews(
        facility_id,
        this.count,
        1
      );
      this.count = this.count + reservationService.totalLoaded;
      this.setReviewsLoading(false);
      const data = this.reviewsData.concat(reviews.data);
      this.setReviewsList(data);
      this.setReviewsData(data);
      !isServer && window.dispatchEvent(new Event('resize'));
    } catch (error) {
      console.log(error);
    }
  }

  async loadReviewsByStar(facility: IFacility, star) {
    const count = 0;
    try {
      this.setReviewsLoading(true);
      const reviews = await reservationService.GetReviewsByStar(
        facility.facility_id,
        count,
        1,
        star
      );
      this.setInitReviewsLoading(false);
      this.setReviewsLoading(false);
      const newData = { ...this.reviewsByStarData };
      newData[star] = reviews.data;
      this.reviewsByStarData = newData;
      const newTotal = { ...this.reviewsByStarTotal };
      newTotal[star] = reviews.recordsTotal ? reviews.recordsTotal : 0;
      console.log(newTotal);
      this.reviewsByStarTotal = newTotal;
      this.reviewsByStarList = newData;
    } catch (error) {
      console.log(error);
    }
  }
  async loadReviews(facility: IFacility, count = 0) {
    try {
      this.setReviewsLoading(true);
      if (facility.facility_url_code.startsWith('arv_')) {
        const reviews = await reservationService.GetReviewsThirdParty(
          facility.facility_url_code
        );
        this.setInitReviewsLoading(false);
        this.setReviewsLoading(false);
        this.setReviewsData(reviews);
        this.setReviewsTotal(reviews.length ? reviews.length : 1);
        this.setReviewsList(reviews);
        return;
      }
      const reviews = await reservationService.GetReviews(
        facility.facility_id,
        count,
        1
      );
      this.setInitReviewsLoading(false);
      this.setReviewsLoading(false);
      this.setReviewsData(reviews.data);
      this.setReviewsTotal(reviews.recordsTotal ? reviews.recordsTotal : 1);
      this.setReviewsList(reviews.data);
    } catch (error) {
      console.log(error);
    }
  }
  async checkCoupon(
    coupon: string,
    email: string,
    facilityId: string,
    facilityUrlCode: string,
    checkin: string,
    checkout: string
  ): Promise<boolean> {
    const validate = await reservationService.checkCoupon(
      coupon,
      email,
      facilityId,
      facilityUrlCode,
      checkin,
      checkout
    );
    if (validate.coupon) {
      this.coupon = validate.coupon;
      this.coupon_applied = coupon;
      this.error_coupon = null;
      return true;
    } else if (validate.error) {
      this.coupon = null;
      this.coupon_applied = null;
      this.error_coupon = validate.error;
    }
    return false;
  }

  resetCouponData() {
    this.coupon = null;
    this.coupon_applied = null;
    this.error_coupon = null;
  }

  async calculatePrice(
    data: any,
    member_id: number,
    checkin?: string,
    checkout?: string
  ) {
    try {
      this.setLoadingPrice(true);
      /*
      const tst_coupon = sessionStorage.getItem('coupon_code');
      if (tst_coupon) {
        this.coupon = { coupon_code: tst_coupon };
        data = { ...data, coupon_code: tst_coupon };
      }
      */
      if (!data.coupon_code && this.coupon?.coupon_code) {
        data = { ...data, coupon_code: this.coupon?.coupon_code };
      }
      const priceDescription = await reservationService.GetPrice(
        data,
        member_id,
        checkin,
        checkout
      );

      this.setBookingPriceDetail(priceDescription);
      this.setLoadingPrice(false);
    } catch (error) {
      this.setLoadingPrice(false);
      console.log(error);
    }
  }

  setLoadingPrice(value: boolean) {
    this.loadingPrice = value;
  }

  async loadDailyRates({ facility_id, facility_url_code }, checkin, checkout) {
    if (facility_url_code?.startsWith('arv')) {
      this.setGroupedDates(null);
      return;
    }
    try {
      const groupedDates = await reservationService.GetDailyRates(
        facility_id,
        this.bookingPriceDetail?.daysParked,
        checkin,
        checkout
      );
      this.setGroupedDates(groupedDates);
    } catch (error) {
      console.log(error);
    }
  }

  setGroupedDates(groupedDates) {
    this.groupedDates = groupedDates;
  }

  setBookingPriceDetail(value) {
    this.bookingPriceDetail = value;
  }

  setCoupon(value) {
    this.coupon = value;
  }

  loadFacilityStatus(
    { date_sold_out, not_sufficient_days },
    validate_warnings?: IValidateWarning[]
  ) {
    let status = 'default';
    if (date_sold_out > 0 && not_sufficient_days > 0) {
      status = 'unavailable';
    } else if (date_sold_out > 0) {
      status = 'soldout';
    } else if (validate_warnings && validate_warnings.length > 0) {
      status = 'unavailablewarnings';
    }
    this.setFacilityStatus(status);
    return status;
  }

  setFacilityStatus(value) {
    this.facilityStatus = value;
  }
}

export default ReservationStore;
